import Carousel from "components/shared/Carousel"
import { useExtra } from "context/ExtraContext"
import { useSizing } from "lib/helpers"
import { urlForImage } from "lib/sanity.image"
import { useMemo } from "react"
import { Quote } from "schemas"
import { Circle, Container, HStack, styled, VStack } from "styled-system/jsx"

type Props = Quote

function Columns({
  sizingTop,
  sizingBottom,

  useNewStyle,
  reviews,

  ...props
}: Props) {
  const { reviews: allReviews } = useExtra()

  const filtered = useMemo(
    () => (reviews?.length ? reviews : allReviews),
    [allReviews, reviews]
  )

  return (
    <styled.div
      className="quote"
      style={
        {
          "--pt": useSizing(sizingTop),
          "--pb": useSizing(sizingBottom),
        } as React.CSSProperties
      }
      css={{
        pt: "var(--pt)",
        pb: "var(--pb)",
        // bg: "other.7",
      }}>
      <Container>
        {useNewStyle && filtered.length > 1 ? (
          <QuoteCarousel reviews={filtered} />
        ) : (
          <SingleQuote {...(filtered[0] ? filtered[0] : props)} />
        )}
      </Container>
    </styled.div>
  )
}

const QuoteCarousel = ({ reviews }: Pick<Props, "reviews">) => {
  if (!reviews.length) return null

  return (
    <Carousel
      slides={reviews.map(review => (
        <SingleQuote key={review._id} {...review} />
      ))}
    />
  )
}

const SingleQuote = ({
  title,
  quote,
  authorName,
  authorTitle,
  authorImage,
  logo,
}: Pick<
  Props,
  "title" | "quote" | "authorName" | "authorTitle" | "authorImage"
>) => {
  return (
    <VStack gap={0}>
      <styled.img
        css={{
          mb: { base: 1, lg: 6 },
        }}
        alt={logo?.alt}
        src={logo ? urlForImage(logo).url() : "/drive-revel.png"}
      />
      <styled.p
        css={{
          textAlign: "center",
          color: "#000A3A",
          fontFamily: "heading",
          fontSize: { base: "12px", md: "30px" },
          fontWeight: 600,
          lineHeight: "150%",
          mb: { base: 2, lg: 8 },
        }}>
        {title}
      </styled.p>
      <HStack
        alignItems="start"
        mb={{ base: 6, lg: 16 }}
        gap={{ base: 0, lg: 8 }}>
        <styled.img src="/open-quote.svg" />
        <styled.p
          css={{
            textAlign: "center",
            color: "#8E69FB",
            fontFamily: "heading",
            fontSize: { base: "12px", md: "30px" },
            fontStyle: "italic",
            fontWeight: 500,
            lineHeight: "150%",
          }}>
          {quote}
        </styled.p>
        <styled.img src="/close-quote.svg" />
      </HStack>
      <HStack gap={{ base: 4, lg: 6 }} alignItems="center">
        {authorImage?.asset && (
          <Circle
            as="img"
            alt={authorImage.alt}
            src={urlForImage(authorImage).size(68, 68).url()}
            size={{ base: "48px", lg: "68px" }}
            css={{
              borderRadius: "50%",
              border: "2px solid #8E69FB",
            }}
          />
        )}
        <VStack alignItems="start" gap={0}>
          <styled.h5
            css={{
              color: "#000A3A",
              fontFamily: "heading",
              fontSize: { base: "18px", md: "24px" },
              fontWeight: 700,
              lineHeight: "150%",
            }}>
            {authorName}
          </styled.h5>
          <styled.p
            css={{
              color: "rgba(0, 10, 58, 0.80)",
              fontSize: { base: "14px", md: "20px" },
              lineHeight: "150%",
            }}>
            {authorTitle}
          </styled.p>
        </VStack>
      </HStack>
    </VStack>
  )
}

export default Columns
export type { Props as ColumnsProps }
