import "keen-slider/keen-slider.min.css"

import { useKeenSlider } from "keen-slider/react"
import { ReactNode, useState } from "react"
import { styled } from "styled-system/jsx"

type Props = {
  slides: ReactNode[]
}

function Carousel({ slides }: Props) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      loop: true,
      slideChanged: slider => setCurrentSlide(slider.track.details.rel),
      created: () => setLoaded(true),
    },
    [
      slider => {
        let timeout: ReturnType<typeof setTimeout>
        let mouseOver = false

        const clearNextTimeout = () => clearTimeout(timeout)

        const nextTimeout = () => {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, 12000)
        }

        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true
            clearNextTimeout()
          })

          slider.container.addEventListener("mouseout", () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })

        slider.on("dragStarted", clearNextTimeout)
        slider.on("animationEnded", nextTimeout)
        slider.on("updated", nextTimeout)
      },
    ]
  )

  return (
    <>
      <styled.div
        css={{
          position: "relative",
        }}>
        <styled.div ref={sliderRef} className="keen-slider">
          {slides.map((slide, i) => (
            <div key={i} className="keen-slider__slide">
              {slide}
            </div>
          ))}
        </styled.div>
        {/*
        {loaded && instanceRef.current && (
          <>
            <Arrow
              left
              onClick={e => {
                e.stopPropagation()
                instanceRef.current?.prev()
              }}
              disabled={currentSlide === 0}
            />
            <Arrow
              onClick={e => {
                e.stopPropagation()
                instanceRef.current?.next()
              }}
              disabled={
                currentSlide ===
                instanceRef.current.track.details.slides.length - 1
              }
            />
          </>
        )}
      */}
      </styled.div>
      {loaded && instanceRef.current && (
        <styled.div
          css={{
            display: "flex",
            padding: "10px 0",
            justifyContent: "center",
          }}>
          {[
            ...Array(instanceRef.current.track.details.slides.length).keys(),
          ].map(index => {
            return (
              <styled.button
                key={index}
                onClick={() => {
                  instanceRef.current?.moveToIdx(index)
                }}
                css={{
                  border: "none",
                  width: "10px",
                  height: "10px",
                  background: "#c5c5c5",
                  borderRadius: "50%",
                  margin: "0 5px",
                  padding: "5px",
                  cursor: "pointer",

                  _focus: {
                    outline: "none",
                  },

                  _active: {
                    background: "#000",
                  },
                }}
                data-active={
                  currentSlide === index ? true : undefined
                }></styled.button>
            )
          })}
        </styled.div>
      )}
    </>
  )
}

export default Carousel
// export type { Props as CarouselProps }
